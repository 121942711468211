var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("text-row", {
        attrs: {
          compact: "",
          "label-cols-lg": 4,
          "label-cols-sm": 4,
          label: "Предмет договора - наименование закупочной сессии",
          value: _vm.purchase.purchase_terms.deliverable_group.name,
        },
      }),
      _c("text-row-price", {
        attrs: {
          compact: "",
          "label-cols-lg": 4,
          "label-cols-sm": 4,
          label: "Цена договора, руб.",
          value: _vm.purchase.contract.price,
        },
      }),
      _c("text-row", {
        attrs: {
          compact: "",
          "label-cols-lg": 4,
          "label-cols-sm": 4,
          label: "Статус договора",
          value: _vm.purchase.contract.status.title,
        },
      }),
      _c("text-row-datetime", {
        attrs: {
          compact: "",
          "label-cols-lg": 4,
          "label-cols-sm": 4,
          label: "Дата",
          value: _vm.purchase.contract.date,
        },
      }),
      _c("text-row", {
        attrs: {
          compact: "",
          "label-cols-lg": 4,
          "label-cols-sm": 4,
          label: "Статус закупки",
          value: _vm.purchase.status.title,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }