<template>
    <div>
        <template>
            <b-row>
                <b-col md="10">
                    <text-header id="atmo-contract">Договор</text-header>
                </b-col>
            </b-row>
            <div v-if="purchase.actions.customer.contract !== 'done'" class="mb-2"> <!-- TODO: добавить ссылку или совсем убрать -->
                <b-btn variant="danger" class="fs-14 my-1 text-uppercase" style="color: white !important" size="lg" :href="$links.fl223_purchase_contract_manual" target="_blank">
                    <i class="atmo-icon-play" />
                    Инструкция по заключению договора
                </b-btn>
            </div>
            <template v-if="purchase.contract">
                <purchase-contract-info :purchase="purchase" />
                <contract-files-table :contract="purchase.contract" :is-data-sending="isDataSending" :download-zip="download" />
                <disagreement-table v-if="purchase.contract.disagreements.length > 0" :disagreements="purchase.contract.disagreements" />
                <template v-if="purchase.actions.customer.contract === 'reply'">
                    <b-row>
                        <b-col md="10">
                            <text-header id="atmo-contract">Согласование разногласий по договору</text-header>
                        </b-col>
                    </b-row>
                    <b-overlay :show="isDataSending" rounded opacity="0.6" spinner-small spinner-variant="primary">
                        <form-row-single-line-document-upload :label-cols-lg="3" v-model="formDisagreement.contract" :v="$v.formDisagreement.contract" title="Новая редакция договора" />
                        <form-row-single-line-document-upload :label-cols-lg="3" v-model="formDisagreement.disagreement" :v="$v.formDisagreement.disagreement" title="Ответ на протокол разногласий" />
                    </b-overlay>
                    <b-overlay :show="isDataSending" rounded opacity="0.6" spinner-small spinner-variant="primary">
                        <b-btn :disabled="!$store.getters.getCanSign || $v.formDisagreement.$invalid" @click="showDisagreementModal = true" variant="custom-green" class="mr-2">Подписать и отправить ответ на протокол разногласий</b-btn>
                    </b-overlay>
                    <template v-if="!$store.getters.getCanSign">
                        <span v-if="$store.getters.getLoginType === 'cert'" class="text-danger pl-3 pt-0 fs-14 w-50 my-auto">Для действия требуется загрузить/заменить МЧД на странице: <router-link :to="{ name: 'UserProfile' }" class="green-link">Данные учетной записи</router-link></span>
                        <span v-else class="text-danger pl-3 pt-0 fs-14 w-50 my-auto">Подписание возможно только при авторизации при помощи ЭЦП</span>
                    </template>
                    <sign-file-modal
                        v-if="formDisagreement.disagreement"
                        title="Отправка подписанного ответа на протокол разногласий"
                        :attachment="formDisagreement.disagreement"
                        :show="showDisagreementModal"
                        @close="showDisagreementModal = false"
                        @submit="storeSignedDisagreement"
                        with-comment
                        comment-required />
                </template>
                <sign-contract-button v-else-if="purchase.actions.customer.contract === 'sign'" :is-data-sending="isDataSending" @click="showConfirmModal = true" />
            </template>
            <template v-else-if="purchase.actions.customer.contract === 'create'">
                <b-overlay :show="isDataSending" rounded opacity="0.6" spinner-small spinner-variant="primary">
                    <form-row-single-line-document-upload :label-cols-lg="3" v-model="form.attachment" :v="$v.form.attachment" @send="send" title="Договор" with-send send-title="Отправить договор" />
                </b-overlay>
            </template>
        </template>
        <template v-if="purchase.contract">
            <sign-file-modal
                title="Отправка подписанного договора"
                :attachment="purchase.contract.contract_files.find((file) => !file.is_archived).contract_attachment"
                :show="showConfirmModal"
                @close="showConfirmModal = false"
                @submit="storeSignedContract" />
        </template>
    </div>
</template>

<script>
import { required } from 'vuelidate/lib/validators';
import {getCertificate} from "crypto-pro";
import ContractFilesTable from "@/components/products/fl223/partials/contract-files-table.vue";
import PurchaseContractInfo from "@/components/products/fl223/partials/purchase-contract-info.vue";
import SignContractButton from "@/components/products/fl223/partials/sign-contract-button.vue";
import FileDownload from "js-file-download";
import DisagreementTable from "@/components/products/fl223/partials/disagreement-table.vue";

export default {
    name: 'private-fl223-contract-customer-block',
    components: {DisagreementTable, SignContractButton, PurchaseContractInfo, ContractFilesTable},
    props: {
        purchase: {
            type: Object,
            required: true
        },
    },
    data() {
        return {
            showConfirmModal: false,
            showDisagreementModal: false,
            form: {
                attachment: null
            },
            formDisagreement: {
                contract: null,
                disagreement: null
            },
            isDataSending: false
        };
    },
    validations() {
        return {
            form: {
                attachment: {
                    required
                }
            },
            formDisagreement: {
                contract: {
                    required
                },
                disagreement: {
                    required
                }
            }
        };
    },
    methods: {
        async send() {
            this.isDataSending = true;
            if (await this.$api.products.fl223.contracts.store(this.purchase.id, this.form.attachment)) {
                this.$emit('refresh');
            }
            this.isDataSending = false;
        },
        async storeSignedContract(signature) {
            this.showConfirmModal = false
            this.isDataSending = true;
            const data = {
                certificate: await getCertificate(this.$store.getters.getCurrentThumbprint),
                signed_attachment: {
                    id: this.purchase.contract.contract_files.find((file) => !file.is_archived).contract_attachment.id,
                    md5: signature.signature
                }
            }
            if (await this.$api.products.fl223.contracts.storeSigned(this.purchase.id, this.purchase.contract.id, 'customer', data)) {
                this.$emit('refresh');
            }
            this.isDataSending = false;
        },
        async storeSignedDisagreement(signature) {
            this.showDisagreementModal = false
            this.isDataSending = true;
            const data = {
                certificate: await getCertificate(this.$store.getters.getCurrentThumbprint),
                comment: signature.comment,
                contract_attachment_id: this.formDisagreement.contract.id,
                signed_attachment: {
                    id: this.formDisagreement.disagreement.id,
                    md5: signature.signature
                }
            };
            const disagreementId = this.purchase.contract.disagreements.find((disagreement) => !disagreement.customer_stored_document).id;
            if (await this.$api.products.fl223.contracts.storeSignedDisagreement(this.purchase.id, this.purchase.contract.id, 'customer', data, disagreementId)) {
                this.$emit('refresh');
            }
            this.isDataSending = false;
        },
        download() {
            this.$api.products.fl223.contracts.downloadZip(this.purchase.id, this.purchase.contract.id, this.purchase.contract.contract_files.find((file) => !file.is_archived).contract_attachment.id)
                .then((response) => {
                    FileDownload(response.data, this.purchase.contract.zip_name);
                })
                .catch((error) => {
                    this.showError(error);
                });
        }
    }
};
</script>
