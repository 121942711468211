<template>
    <div>
        <b-btn v-if="proposal.actions.supplier?.contract === 'sign'" class="btn-card-action btn-card-action-danger my-1" :to="{ name: 'Fl223PurchaseShow', params: { id: proposal.purchase.id }, hash: '#atmo-contract' }" target="_blank">Подписать договор</b-btn>
    </div>
</template>

<script>
export default {
    name: 'cabinet-fl223-proposal-card-contract-button',
    props: {
        proposal: {
            type: Object
        }
    }
};
</script>
