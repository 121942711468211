<template>
    <div>
        <b-overlay :show="isDataSending" rounded opacity="0.6" spinner-small spinner-variant="primary">
            <b-btn :disabled="!$store.getters.getCanSign" @click="$emit('click')" variant="custom-green" class="mr-2">Подписать и отправить договор</b-btn>
        </b-overlay>
        <template v-if="!$store.getters.getCanSign">
            <span v-if="$store.getters.getLoginType === 'cert'" class="text-danger pl-3 pt-0 fs-14 w-50 my-auto">Для действия требуется загрузить/заменить МЧД на странице: <router-link :to="{ name: 'UserProfile' }" class="green-link">Данные учетной записи</router-link></span>
            <span v-else class="text-danger pl-3 pt-0 fs-14 w-50 my-auto">Подписание возможно только при авторизации при помощи ЭЦП</span>
        </template>
    </div>
</template>

<script>
export default {
    name: 'sign-contract-button',
    props: {
        isDataSending: {
            type: Boolean,
            default: false
        }
    }
};
</script>
