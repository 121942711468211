import { render, staticRenderFns } from "./public-fl223-contract-block.vue?vue&type=template&id=24c23be6&"
import script from "./public-fl223-contract-block.vue?vue&type=script&lang=js&"
export * from "./public-fl223-contract-block.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/var/www/stage-fl223/front/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('24c23be6')) {
      api.createRecord('24c23be6', component.options)
    } else {
      api.reload('24c23be6', component.options)
    }
    module.hot.accept("./public-fl223-contract-block.vue?vue&type=template&id=24c23be6&", function () {
      api.rerender('24c23be6', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/products/fl223/partials/public-fl223-contract-block.vue"
export default component.exports