var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "b-button",
        {
          attrs: { size: "sm", variant: "custom-danger" },
          on: { click: _vm.showModal },
        },
        [_vm._v("ЗАПОЛНИТЬ")]
      ),
      _c(
        "b-modal",
        {
          attrs: {
            title: _vm.title,
            centered: "",
            "hide-header-close": "",
            "no-close-on-backdrop": "",
            "no-close-on-esc": "",
            size: "xl",
          },
          model: {
            value: _vm.isModalVisible,
            callback: function ($$v) {
              _vm.isModalVisible = $$v
            },
            expression: "isModalVisible",
          },
        },
        [
          _c(
            "div",
            { staticClass: "table-responsive" },
            [
              _c("b-table", {
                staticClass: "table-atmo",
                attrs: {
                  busy: _vm.isLoading,
                  fields: _vm.fields,
                  items: _vm.form.details,
                },
                scopedSlots: _vm._u([
                  {
                    key: "cell(user_total_count)",
                    fn: function (row) {
                      return [
                        _c("b-input", {
                          directives: [
                            {
                              name: "stop-number-mousewheel",
                              rawName: "v-stop-number-mousewheel",
                            },
                          ],
                          staticClass: "fs-14",
                          attrs: {
                            state:
                              !_vm.$v.form.details.$each.$iter[row.index]
                                .user_total_count.$invalid,
                            type: "number",
                          },
                          model: {
                            value: _vm.form.details[row.index].user_total_count,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.form.details[row.index],
                                "user_total_count",
                                _vm._n($$v)
                              )
                            },
                            expression:
                              "form.details[row.index].user_total_count",
                          },
                        }),
                      ]
                    },
                  },
                  {
                    key: "cell(user_total_sum)",
                    fn: function (row) {
                      return [
                        _c("currency-input", {
                          directives: [
                            {
                              name: "stop-number-mousewheel",
                              rawName: "v-stop-number-mousewheel",
                            },
                          ],
                          staticClass: "form-control fs-14",
                          class: !_vm.$v.form.details.$each.$iter[row.index]
                            .user_total_sum.$invalid
                            ? "is-valid"
                            : "is-invalid",
                          attrs: {
                            "allow-negative": false,
                            currency: "RUB",
                            locale: "ru",
                          },
                          model: {
                            value: _vm.form.details[row.index].user_total_sum,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.form.details[row.index],
                                "user_total_sum",
                                $$v
                              )
                            },
                            expression:
                              "form.details[row.index].user_total_sum",
                          },
                        }),
                      ]
                    },
                  },
                  {
                    key: "cell(user_local_count)",
                    fn: function (row) {
                      return [
                        _c("b-input", {
                          directives: [
                            {
                              name: "stop-number-mousewheel",
                              rawName: "v-stop-number-mousewheel",
                            },
                          ],
                          staticClass: "fs-14",
                          attrs: {
                            state:
                              !_vm.$v.form.details.$each.$iter[row.index]
                                .user_local_count.$invalid,
                            type: "number",
                          },
                          model: {
                            value: _vm.form.details[row.index].user_local_count,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.form.details[row.index],
                                "user_local_count",
                                _vm._n($$v)
                              )
                            },
                            expression:
                              "form.details[row.index].user_local_count",
                          },
                        }),
                      ]
                    },
                  },
                  {
                    key: "cell(user_local_sum)",
                    fn: function (row) {
                      return [
                        _c("currency-input", {
                          directives: [
                            {
                              name: "stop-number-mousewheel",
                              rawName: "v-stop-number-mousewheel",
                            },
                          ],
                          staticClass: "form-control fs-14",
                          class: !_vm.$v.form.details.$each.$iter[row.index]
                            .user_local_sum.$invalid
                            ? "is-valid"
                            : "is-invalid",
                          attrs: {
                            "allow-negative": false,
                            currency: "RUB",
                            locale: "ru",
                          },
                          model: {
                            value: _vm.form.details[row.index].user_local_sum,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.form.details[row.index],
                                "user_local_sum",
                                $$v
                              )
                            },
                            expression:
                              "form.details[row.index].user_local_sum",
                          },
                        }),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
          !_vm.$v.form.details.totalGreaterThanLocal
            ? _c("p", { staticClass: "text-danger fs-14" }, [
                _vm._v(
                  "Количество/сумма закупок у поставщиков из РБ не может быть больше общего количества/суммы."
                ),
              ])
            : _vm._e(),
          _c("p", { staticClass: "grey-color fs-14" }, [
            _vm._v(
              "Необходимо внести информацию о количестве и суммах закупок по каждому основанию с начала текущего года на отчетную дату."
            ),
          ]),
          _c(
            "div",
            {
              staticClass: "w-100 text-right",
              attrs: { slot: "modal-footer" },
              slot: "modal-footer",
            },
            [
              _c(
                "b-overlay",
                {
                  staticClass: "d-inline-block",
                  attrs: {
                    show: _vm.isDataSending,
                    opacity: "0.6",
                    rounded: "",
                    "spinner-small": "",
                    "spinner-variant": "primary",
                  },
                },
                [
                  _c(
                    "b-button",
                    {
                      staticClass: "text-uppercase mr-2",
                      attrs: { variant: "custom-outline-secondary" },
                      on: { click: _vm.cancelModal },
                    },
                    [_vm._v("Отмена")]
                  ),
                ],
                1
              ),
              _c(
                "b-overlay",
                {
                  staticClass: "d-inline-block",
                  attrs: {
                    show: _vm.isDataSending,
                    opacity: "0.6",
                    rounded: "",
                    "spinner-small": "",
                    "spinner-variant": "primary",
                  },
                },
                [
                  _c(
                    "b-button",
                    {
                      staticClass: "text-uppercase",
                      attrs: {
                        disabled: _vm.$v.$invalid,
                        variant: "custom-green",
                      },
                      on: { click: _vm.sendReport },
                    },
                    [_vm._v("Отправить отчет")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }