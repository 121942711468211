var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.protocol
    ? _c(
        "div",
        { staticClass: "my-2" },
        [
          _c("text-header", { attrs: { id: "atmo-protocol" } }, [
            _vm._v("Итоговый протокол закупочной сессии"),
          ]),
          _c("text-row", {
            attrs: {
              compact: true,
              label: "Регистрационный номер",
              value: _vm.protocol.reg_number,
            },
          }),
          _c("text-row", {
            attrs: {
              compact: true,
              label: "Статус",
              value: _vm.protocol.status.title,
            },
          }),
          _c("text-row-single-document", {
            attrs: {
              compact: true,
              label:
                "Подписанный членами комиссии итоговый протокол закупочной сессии",
            },
            model: {
              value: _vm.protocol.attachment,
              callback: function ($$v) {
                _vm.$set(_vm.protocol, "attachment", $$v)
              },
              expression: "protocol.attachment",
            },
          }),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }