<template>
    <div class="atmo-page-entities-list">
        <b-container class="atmo-page-banner-wrapper main_layer" fluid>
            <b-container class="atmo-container-fixed" fluid>
                <h2 class="mb-4">Реестр контрактов 44-ФЗ</h2>
                <b-form v-on:focusin="alert = false" autocomplete="off" ref="filter" class="atmo-entity-filters w-100">
                    <b-row class="atmo-entity-filters">
                        <filter-multiple-select class="atmo-entity-filters" label="Предмет закупки" v-model="filter.purchaseCategories" :options="forFilters.purchaseCategories" placeholder="Выберите предметы закупки" md="6" lg="6" />
                        <filter-input-text label="Наименование ТРУ" v-model="filter.conditionName" placeholder="Укажите наименование ТРУ" md="6" lg="6" />
                        <template v-if="showFilters">
                            <filter-input-text label="Рег.номер закупки" v-model="filter.regNumber" placeholder="Укажите номер закупки" />
                            <filter-input-text label="Заказчик" v-model="filter.customer" placeholder="Укажите наименование/ИНН заказчика" />
                            <filter-input-text label="Поставщик" v-model="filter.provider" placeholder="Укажите наименование/ИНН поставщика" />
                            <filter-single-select label="Тип договора" placeholder="Укажите тип договора" v-model="filter.type" :options="forFilters.contractTypes" />
                            <filter-input-currency label="НЦК от..." v-model="filter.fromStartPrice" placeholder="НЦК от..." />
                            <filter-input-currency label="НЦК до..." v-model="filter.toStartPrice" placeholder="НЦК до..." />
                            <b-col cols="12" md="6" lg="3" class="mb-3">
                                <date-time-picker
                                    class="filter_datepicker"
                                    id="filter_from_purchase_start"
                                    v-model="filter.fromPublishedAt"
                                    label="Укажите дату размещения с"
                                    :only-date="true"
                                    formatted="DD.MM.YYYY"
                                    format="YYYY-MM-DD"
                                    buttonNowTranslation="Сегодня"
                                    :no-label="!!filter.fromPublishedAt"
                                    autoClose />
                                <span class="fs-10 halfWhite-color label-position">Дата размещения с...</span>
                            </b-col>
                            <b-col cols="12" md="6" lg="3" class="mb-3">
                                <date-time-picker
                                    class="filter_datepicker"
                                    id="filter_to_purchase_start"
                                    v-model="filter.toPublishedAt"
                                    label="Укажите дату размещения по"
                                    :only-date="true"
                                    formatted="DD.MM.YYYY"
                                    format="YYYY-MM-DD"
                                    buttonNowTranslation="Сегодня"
                                    :no-label="!!filter.toPublishedAt"
                                    autoClose />
                                <span class="fs-10 halfWhite-color label-position">Дата размещения по...</span>
                            </b-col>
                            <filter-multiple-select class="atmo-entity-filters" label="Муниципалитеты" v-model="filter.municipalities" :options="forFilters.municipalities" placeholder="Выберите муниципалитет" md="12" lg="12" />
                        </template>
                    </b-row>
                    <b-row class="mt-1">
                        <b-col class="atmo-buttons atmo-special atmo-mobile">
                            <b-button class="mr-2 text-uppercase" variant="custom-white" @click="applyFilters(false)">Найти</b-button>
                            <b-button class="mr-2 text-uppercase" variant="custom-outline-light" @click="showFilters = !showFilters">{{ showFilters ? 'Меньше фильтров' : 'Все фильтры' }} </b-button>
                            <b-button class="mr-2 text-uppercase" variant="custom-outline-light" @click="clearFilter()">Сбросить</b-button>
                        </b-col>
                    </b-row>
                </b-form>
            </b-container>
        </b-container>
        <b-container fluid>
            <b-container>
                <template v-if="!mobileMenu">
                    <b-nav class="atmo-status-menu-controller atmo-menu nav-menu">
                        <b-nav-item v-for="(category, cIndex) in contract_categories" :key="'category_' + cIndex" :to="getNavLink(category.path)">{{ category.title }}</b-nav-item>
                    </b-nav>
                </template>
                <template v-else>
                    <b-navbar toggleable class="atmo-status-menu-controller atmo-menu nav-mobile-menu">
                        <b-navbar-toggle target="navbar-toggle-collapse">
                            <template #default="{ expanded }">
                                <b-icon v-if="expanded" icon="chevron-bar-up"></b-icon>
                                <b-icon v-else icon="chevron-bar-down"></b-icon>
                                <span class="pl-1">{{ navTitle }}</span>
                            </template>
                        </b-navbar-toggle>
                        <b-collapse id="navbar-toggle-collapse" is-nav>
                            <b-navbar-nav class="ml-auto">
                                <b-nav-item v-for="(category, cIndex) in contract_categories" :key="'category_' + cIndex" :to="getNavLink(category.path)" exact exact-active-class="disabled">{{ category.title }}</b-nav-item>
                            </b-navbar-nav>
                        </b-collapse>
                    </b-navbar>
                </template>
            </b-container>
        </b-container>
        <b-container fluid id="content-block" class="greyBg content-block-with-nav">
            <b-overlay :show="isLoading" rounded opacity="0.6" spinner-variant="primary">
                <b-container v-if="isLoading || !contracts.length">
                    <empty-request />
                </b-container>
                <template v-else>
                    <b-pagination v-if="contracts && meta.total > 0 && meta.last_page > 1" v-model="meta.current_page" :per-page="meta.per_page" :total-rows="meta.total" align="center" class="my-1 pb-0" />
                    <b-container v-for="contract in contracts" :key="`${contract.type}_${contract.id}`">
                        <fl44-contract-card v-if="contract.type === 'fl44'" :contract="contract" />
                        <fl44-external-purchase-card v-else :contract="contract" />
                    </b-container>
                    <b-pagination v-if="contracts && meta.total > 0 && meta.last_page > 1" v-model="meta.current_page" :per-page="meta.per_page" :total-rows="meta.total" align="center" class="my-1" @input="applyFilters()" />
                    <span v-else>&nbsp;</span>
                </template>
            </b-overlay>
        </b-container>
    </div>
</template>

<script>
import FilterMultipleSelect from '@/components/common/filter-components/filter-multiple-select.vue';
import router from '@/router';
import Fl44ContractCard from '@/components/global/fl44-contracts/partials/fl44-contract-card.vue';
import Fl44ExternalPurchaseCard from '@/components/global/fl44-contracts/partials/fl44-external-purchase-card.vue';

const emptyFilter = {
    purchaseCategories: [],
    conditionName: '',
    orderType: [],
    customer: '',
    provider: '',
    regNumber: '',
    fromPublishedAt: null,
    toPublishedAt: null,
    fromStartPrice: null,
    toStartPrice: null,
    type: '',
    municipalities: []
};

export default {
    name: 'ContractsList',
    components: { Fl44ExternalPurchaseCard, Fl44ContractCard, FilterMultipleSelect },
    metaInfo: {
        title: 'Реестр контрактов 44-ФЗ'
    },
    data() {
        return {
            isLoading: true,
            showFilters: false,
            filter: { ...emptyFilter },
            forFilters: {
                contractTypes: [
                    { id: '', name: 'Любой' },
                    { id: 'fl44', name: 'В ИС' },
                    { id: 'fl44_external', name: 'Вне ИС' }
                ],
                purchaseCategories: [],
                municipalities: []
            },
            mobileMenu: false,
            mobileMenuShow: false,
            contract_categories: [
                { title: 'Все', path: 'all' },
                { title: 'Договор заключен', path: 'concluded' },
                { title: 'Договор завершен', path: 'finished' },
                { title: 'Договор не заключен', path: 'failed' }
            ],
            currentRoute: null,
            contracts: [],
            meta: {
                current_page: 1,
                total: 0,
                per_page: 15,
                last_page: 0
            }
        };
    },
    created() {
        this.currentRoute = this.$route.params.contract;
        const onResize = () => (this.mobileMenu = window.innerWidth <= 767);
        onResize();
        window.addEventListener('resize', onResize);
        this.$on('hook:beforeDestroy', () => window.removeEventListener('resize', onResize));
    },
    async mounted() {
        await this.fillFilters();
        await this.getContracts();
        this.$scrollToTop();
    },
    methods: {
        getNavLink(path) {
            return { name: this.$route.name, params: { contract: path }, query: this.getQueryParams(this.$route.params.contract === path) };
        },
        async getContracts(page = true) {
            this.isLoading = true;
            const contractsData = await this.$api.products.fl44.contracts.getList(this.getFilters(page));
            this.contracts = contractsData.data;
            this.meta = contractsData.meta;
            this.isLoading = false;
        },
        async applyFilters(page = true) {
            await router.push({ name: this.$route.name, params: this.$route.params, query: this.getQueryParams(page) }).catch(() => {});
        },
        getQueryParams(page = true) {
            const filterValues = [];
            for (const [key, value] of Object.entries(this.filter)) {
                if (!value || (Array.isArray(value) && value.length === 0)) continue;
                filterValues[key] = value;
                filterValues.length++;
            }
            const result = {};
            if (filterValues.length) result.filter = JSON.stringify(Object.assign({}, filterValues));
            if (page && this.meta.current_page > 1) result.page = this.meta.current_page;
            return result;
        },
        async fillFilters() {
            const data = await this.$api.dictionary.getList(['purchase_categories']);
            this.forFilters.purchaseCategories = data.purchase_categories;
            this.forFilters.municipalities = this.$globalDictionaries.municipalities;
            this.filter = Object.assign(this.filter, this.$route.query.filter ? JSON.parse(this.$route.query.filter) : emptyFilter);
            this.meta.current_page = this.$route.query.page ?? 1;
        },
        getFilters(page = true) {
            this.filter = Object.assign(this.filter, this.$route.query.filter ? JSON.parse(this.$route.query.filter) : emptyFilter);
            this.meta.current_page = this.$route.query.page ?? 1;
            const filterValues = [];
            for (const [key, value] of Object.entries(this.filter)) {
                if (value) {
                    filterValues[key] = value;
                }
            }
            return {
                ...filterValues,
                page: page ? this.meta.current_page : 1,
                status: this.$route.params.contract
            };
        },
        async clearFilter() {
            this.filter = { ...emptyFilter };
            await router.push({ name: this.$route.name, params: this.$route.params }).catch(() => {});
        }
    },
    computed: {
        navTitle() {
            const currentCategory = this.contract_categories.filter((category) => {
                return category.path.match(this.currentRoute);
            });
            return currentCategory[0].title;
        }
    },
    watch: {
        $route: function (to) {
            this.currentRoute = to.params.contract;
            this.getContracts(!!to.query.page);
        }
    },
};
</script>

<style scoped>
.label-position {
    display: block;
    margin-top: 5px;
}

.nav-link,
.nav-item,
.nav-menu,
.nav-mobile-menu {
    padding-left: 0 !important;
    padding-right: 0 !important;
    margin-bottom: -0.04rem !important;
}

.nav-menu {
    font-family: unset;
    font-weight: unset;
    font-size: 14px;
    color: #666666;
}

.nav-menu :hover {
    color: #555555;
}

.nav-link.router-link-active {
    color: #26b89a !important;
    border-bottom: 2px solid #26b89a;
    font-weight: unset;
    pointer-events: none;
    cursor: default;
}

.navbar-toggler {
    border: none;
    font-size: 14px;
    color: var(--green-color);
    padding-left: 0px;
}

.nav-mobile-menu .navbar-toggler :hover {
    color: var(--green-color);
}

.nav-mobile-menu {
    border: none;
}

.navbar-collapse {
    font-size: 14px;
}
</style>
