<template>
    <div>
        <template>
            <b-row>
                <b-col md="10">
                    <text-header id="atmo-contract">Договор</text-header>
                </b-col>
            </b-row>
            <div v-if="purchase.actions.supplier.contract !== 'done'" class="mb-2"> <!-- TODO: добавить ссылку или совсем убрать -->
                <b-btn variant="danger" class="fs-14 my-1 text-uppercase" style="color: white !important" size="lg" :href="$links.fl223_purchase_contract_manual" target="_blank">
                    <i class="atmo-icon-play" />
                    Инструкция по заключению договора
                </b-btn>
            </div>
            <template v-if="purchase.contract">
                <purchase-contract-info :purchase="purchase" />
                <contract-files-table :contract="purchase.contract" :is-data-sending="isDataSending" :download-zip="download" />
                <disagreement-table v-if="purchase.contract.disagreements.length > 0" :disagreements="purchase.contract.disagreements" />
                <template v-if="purchase.actions.supplier.contract === 'sign'">
                    <sign-contract-button :is-data-sending="isDataSending" @click="showConfirmModal = true" />
                    <b-row>
                        <b-col md="10">
                            <text-header id="atmo-contract">Согласование разногласий по договору</text-header>
                        </b-col>
                    </b-row>
                    <b-overlay :show="isDataSending" rounded opacity="0.6" spinner-small spinner-variant="primary">
                        <form-row-single-line-document-upload :disabled="!$store.getters.getCanSign" :label-cols-lg="3" v-model="form.attachment" :v="$v.form.attachment" @send="showDisagreementModal = true" title="Протокол разногласий" with-send send-title="Подписать и отправить протокол разногласий" />
                    </b-overlay>
                    <template v-if="!$store.getters.getCanSign">
                        <div v-if="$store.getters.getLoginType === 'cert'" class="text-danger pl-3 pt-0 fs-14 w-50 my-auto">Для действия требуется загрузить/заменить МЧД на странице: <router-link :to="{ name: 'UserProfile' }" class="green-link">Данные учетной записи</router-link></div>
                        <div v-else class="text-danger pl-3 pt-0 fs-14 w-50 my-auto">Подписание возможно только при авторизации при помощи ЭЦП</div>
                    </template>
                </template>
            </template>
        </template>
        <sign-file-modal
            title="Отправка подписанного договора"
            :attachment="purchase.contract.contract_files.find((file) => !file.is_archived).contract_attachment"
            :show="showConfirmModal"
            @close="showConfirmModal = false"
            @submit="storeSignedContract" />
        <sign-file-modal
            v-if="form.attachment"
            title="Отправка подписанного протокола разногласий"
            :attachment="form.attachment"
            :show="showDisagreementModal"
            @close="showDisagreementModal = false"
            @submit="storeSignedDisagreement"
            with-comment />
    </div>
</template>

<script>
import { required } from 'vuelidate/lib/validators';
import {getCertificate} from "crypto-pro";
import ContractFilesTable from "@/components/products/fl223/partials/contract-files-table.vue";
import SignContractButton from "@/components/products/fl223/partials/sign-contract-button.vue";
import PurchaseContractInfo from "@/components/products/fl223/partials/purchase-contract-info.vue";
import FileDownload from "js-file-download";
import DisagreementTable from "@/components/products/fl223/partials/disagreement-table.vue";

export default {
    name: 'private-fl223-contract-supplier-block',
    components: {DisagreementTable, PurchaseContractInfo, SignContractButton, ContractFilesTable},
    props: {
        purchase: {
            type: Object,
            required: true
        },
    },
    data() {
        return {
            showConfirmModal: false,
            showDisagreementModal: false,
            form: {
                attachment: null
            },
            isDataSending: false,
            fields: [
                { key: 'created_at', label: 'Дата и время', sortable: false, tdClass: 'align-text-top text-nowrap py-1', thClass: 'py-1', thStyle: { width: '200px', maxWidth: '200px' } },
                { key: 'contract_attachment', label: 'Документ', sortable: false, tdClass: 'align-text-top py-1', thClass: 'text-nowrap py-1' },
                { key: 'sign_customer', label: 'Подпись заказчика', sortable: false, tdClass: 'align-text-top text-nowrap py-1', thClass: 'py-1', thStyle: { width: '50px' } },
                { key: 'sign_supplier', label: 'Подпись поставщика', sortable: false, tdClass: 'align-text-top text-nowrap py-1', thClass: 'py-1', thStyle: { width: '50px' } }
            ],
        };
    },
    validations() {
        return {
            form: {
                attachment: {
                    required
                }
            }
        };
    },
    methods: {
        async send() {
            this.isDataSending = true;
            if (await this.$api.products.fl223.contracts.store(this.purchase.id, this.form.attachment)) {
                this.$emit('refresh');
            }
            this.isDataSending = false;
        },
        async storeSignedContract(signature) {
            this.showConfirmModal = false
            this.isDataSending = true;
            const data = {
                certificate: await getCertificate(this.$store.getters.getCurrentThumbprint),
                signed_attachment: {
                    id: this.purchase.contract.contract_files.find((file) => !file.is_archived).contract_attachment.id,
                    md5: signature.signature
                }
            }
            if (await this.$api.products.fl223.contracts.storeSigned(this.purchase.id, this.purchase.contract.id, 'supplier', data)) {
                this.$emit('refresh');
            }
            this.isDataSending = false;
        },
        async storeSignedDisagreement(signature) {
            this.showDisagreementModal = false
            this.isDataSending = true;
            const data = {
                certificate: await getCertificate(this.$store.getters.getCurrentThumbprint),
                comment: signature.comment,
                signed_attachment: {
                    id: this.form.attachment.id,
                    md5: signature.signature
                }
            }
            if (await this.$api.products.fl223.contracts.storeSignedDisagreement(this.purchase.id, this.purchase.contract.id, 'supplier', data)) {
                this.$emit('refresh');
            }
            this.isDataSending = false;
        },
        download() {
            this.$api.products.fl223.contracts.downloadZip(this.purchase.id, this.purchase.contract.id, this.purchase.contract.contract_files.find((file) => !file.is_archived).contract_attachment.id)
                .then((response) => {
                    FileDownload(response.data, this.purchase.contract.zip_name);
                })
                .catch((error) => {
                    this.showError(error);
                });
        }
    }
};
</script>
