<template>
    <b-nav class="w-100 provider_menu cabinet_menu mb-3" vertical>
        <cabinet-submenu v-if="isUserProvider || isUserCustomer || orgRoleIsPermitted($orgRoles.CUSTOMER_FL44) || orgRoleIsPermitted($orgRoles.SUPPLIER_FL44)" path="/cabinet/fl44" title="44-ФЗ" strong>
            <cabinet-submenu-item v-if="isUserCustomer || orgRoleIsPermitted($orgRoles.CUSTOMER_FL44)" :to="{ name: 'CustomerPurchasesCabinet', params: { cat: 'all' } }">Извещения</cabinet-submenu-item>
            <cabinet-submenu-item v-if="isUserCustomer || orgRoleIsPermitted($orgRoles.CUSTOMER_FL44)" :to="{ name: 'CustomerContractsCabinet', params: { cat: 'all' } }">Контракты</cabinet-submenu-item>
            <cabinet-submenu-item v-if="isUserProvider || orgRoleIsPermitted($orgRoles.SUPPLIER_FL44)" :to="{ name: 'ProviderContractsCabinet', params: { cat: 'all' } }">Контракты</cabinet-submenu-item>
            <cabinet-submenu-item v-if="orgRoleIsPermitted($orgRoles.SUPPLIER_FL44)" :to="{ name: 'CabinetFl44ProposalsList' }"> Предложения </cabinet-submenu-item>
            <cabinet-submenu-item v-if="orgRoleIsPermitted($orgRoles.CUSTOMER_FL44)" :to="{ name: 'CabinetFl44PurchaseReports' }">Отчеты по закупкам</cabinet-submenu-item>
        </cabinet-submenu>

        <cabinet-submenu v-if="orgRoleIsPermitted($orgRoles.CUSTOMER_FL223) || orgRoleIsPermitted($orgRoles.SUPPLIER_FL223)" path="/cabinet/fl223" title="223-ФЗ" strong>
            <cabinet-submenu-item v-if="orgRoleIsPermitted($orgRoles.CUSTOMER_FL223)" :to="{ name: 'CabinetFl223Notices' }">Извещения</cabinet-submenu-item>
            <cabinet-submenu-item :to="{ name: 'CabinetFl223Contracts' }">Контракты</cabinet-submenu-item>
            <cabinet-submenu-item v-if="orgRoleIsPermitted($orgRoles.SUPPLIER_FL223)" :to="{ name: 'CabinetFl223ProposalsList' }">Предложения</cabinet-submenu-item>
            <cabinet-submenu-item v-if="orgRoleIsPermitted($orgRoles.CUSTOMER_FL223)" :to="{ name: 'CabinetFl223Settings' }">Настройки</cabinet-submenu-item>
        </cabinet-submenu>

        <cabinet-submenu v-if="orgRoleIsPermitted($orgRoles.CUSTOMER_COMMERCIAL) || orgRoleIsPermitted($orgRoles.SUPPLIER_COMMERCIAL)" path="/cabinet/commercial" title="Коммерческие" strong>
            <cabinet-submenu-item v-if="orgRoleIsPermitted($orgRoles.CUSTOMER_COMMERCIAL)" :to="{ name: 'CabinetCommercialNotices' }"> Извещения </cabinet-submenu-item>
            <!--            <cabinet-submenu-item :to="{ name: 'CabinetCommercialContracts' }">Договоры</cabinet-submenu-item>-->
            <cabinet-submenu-item v-if="orgRoleIsPermitted($orgRoles.SUPPLIER_COMMERCIAL)" :to="{ name: 'CabinetCommercialProposalsList' }"> Предложения </cabinet-submenu-item>
        </cabinet-submenu>

        <cabinet-submenu path="/cabinet/quotation_requests" title="Запросы КП">
            <cabinet-submenu-item :to="{ name: 'QuotationRequestsRequestsList', params: { cat: 'all' } }">Запросы</cabinet-submenu-item>
            <cabinet-submenu-item :to="{ name: 'QuotationRequestsOffersList', params: { cat: 'all' } }">Предложения</cabinet-submenu-item>
        </cabinet-submenu>
        <cabinet-menu-item v-if="isProductionsAllowed && isUserProvider" :to="{ name: 'ProviderProductionsList' }">Мое производство</cabinet-menu-item>
        <cabinet-menu-item v-if="isUserProvider" :to="{ name: 'ProviderAccountCabinet' }">Лицевой счет</cabinet-menu-item>
        <cabinet-submenu path="/cabinet/ktru" title="КТРУ">
            <cabinet-submenu-item :to="{ name: 'KtruRequestsList' }">Запросы на добавление позиции</cabinet-submenu-item>
            <cabinet-submenu-item :to="{ name: 'KtruFavoritesList' }">Избранное<span class="atmo-badge" style="left: 94px; top: 5px">Новое</span></cabinet-submenu-item>
        </cabinet-submenu>
        <cabinet-menu-item :to="{ name: 'NotificationsList' }">Системные уведомления</cabinet-menu-item>
        <cabinet-menu-item :to="{ name: 'PersonalAddressesCabinet' }">Адресная книга</cabinet-menu-item>
        <cabinet-menu-item v-if="isUserProvider" :to="{ name: 'OrganizationReliabilityReport' }">Проверка контрагентов</cabinet-menu-item>

        <cabinet-submenu :path="['/cabinet/organization', '/cabinet/user_profile']" title="Об организации">
            <cabinet-submenu-item :to="{ name: 'OrganizationData' }">Информация об организации</cabinet-submenu-item>
            <cabinet-submenu-item :to="{ name: 'UserProfile' }">Данные учетной записи</cabinet-submenu-item>
            <cabinet-submenu-item :to="{ name: 'UsersList' }">Пользователи организации</cabinet-submenu-item>
            <cabinet-submenu-item v-if="orgRoleIsPermitted($orgRoles.CUSTOMER_FL44)" :to="{ name: 'CabinetFl44Limits' }">Лимиты БО</cabinet-submenu-item>
        </cabinet-submenu>
    </b-nav>
</template>

<script>
import CabinetMenuItem from '@/components/cabinets/CabinetMenuItem.vue';
import CabinetSubmenu from '@/components/cabinets/CabinetSubmenu.vue';
import CabinetSubmenuItem from '@/components/cabinets/CabinetSubmenuItem.vue';
import { mapGetters } from 'vuex';

export default {
    name: 'cabinet-sidenav',
    components: { CabinetSubmenuItem, CabinetSubmenu, CabinetMenuItem },
    methods: {
        isActive(path) {
            return this.$route.name === path;
        }
    },
    computed: {
        ...mapGetters(['isProductionsAllowed']),
        isUserCustomer() {
            return this.$store.state.organization && !this.$store.state.organization.is_provider;
        },
        isUserProvider() {
            return this.$store.state.organization && this.$store.state.organization.is_provider;
        }
    }
};
</script>
<style scoped>
.cabinet_menu {
    position: -webkit-sticky;
    position: sticky;
    top: 160px;
    -webkit-box-shadow: 0 0 10px -5px #aaa;
    -moz-box-shadow: 0 0 10px -5px #aaa;
    box-shadow: 0 0 10px -5px #aaa;
}

.cabinet_menu li {
    position: relative;
    background: #fff;
}

.cabinet_menu li a {
    border-left: 8px solid transparent;
    font-size: 14px;
    padding: 10px;
    color: var(--grey-color);
}

.cabinet_menu li + li {
    border-top: 1px solid rgba(149, 155, 164, 0.25);
}

.provider_menu .cabinet_submenu li {
    border-top: none !important;
}

.provider_menu .cabinet_submenu li a {
    font-size: 14px;
    color: var(--grey-color);
}

.provider_menu .cabinet_submenu li a:active {
    background: transparent;
}

.provider_menu .cabinet_submenu li a:hover {
    background: transparent;
}
</style>
