var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "b-row",
        [
          _c(
            "b-col",
            { attrs: { md: "10" } },
            [
              _c("text-header", { attrs: { id: "atmo-contract" } }, [
                _vm._v("Договор"),
              ]),
            ],
            1
          ),
        ],
        1
      ),
      _c("purchase-contract-info", { attrs: { purchase: _vm.purchase } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }