var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "atmo-page-entities-list" },
    [
      _c(
        "b-container",
        {
          staticClass: "atmo-page-banner-wrapper main_layer",
          attrs: { fluid: "" },
        },
        [
          _c(
            "b-container",
            { staticClass: "atmo-container-fixed", attrs: { fluid: "" } },
            [
              _c("h2", { staticClass: "mb-4" }, [
                _vm._v("Реестр извещений 223-ФЗ"),
              ]),
              _c(
                "b-form",
                {
                  ref: "filter",
                  staticClass: "atmo-entity-filters w-100",
                  attrs: { autocomplete: "off" },
                  on: {
                    focusin: function ($event) {
                      _vm.alert = false
                    },
                  },
                },
                [
                  _c(
                    "b-row",
                    { staticClass: "atmo-entity-filters" },
                    [
                      _c("filter-multiple-select", {
                        staticClass: "atmo-entity-filters",
                        attrs: {
                          label: "Предмет закупки",
                          options: _vm.forFilters.purchaseCategories,
                          placeholder: "Выберите предметы закупки",
                          md: "6",
                          lg: "6",
                        },
                        model: {
                          value: _vm.filter.purchaseCategories,
                          callback: function ($$v) {
                            _vm.$set(_vm.filter, "purchaseCategories", $$v)
                          },
                          expression: "filter.purchaseCategories",
                        },
                      }),
                      _c("filter-input-text", {
                        attrs: {
                          label: "Наименование ТРУ",
                          placeholder: "Укажите наименование ТРУ",
                          md: "6",
                          lg: "6",
                        },
                        model: {
                          value: _vm.filter.conditionName,
                          callback: function ($$v) {
                            _vm.$set(_vm.filter, "conditionName", $$v)
                          },
                          expression: "filter.conditionName",
                        },
                      }),
                      _vm.showFilters
                        ? [
                            _c("filter-input-text", {
                              attrs: {
                                label: "Объект закупки",
                                placeholder: "Укажите объект закупки",
                                md: "6",
                                lg: "6",
                              },
                              model: {
                                value: _vm.filter.purchaseObject,
                                callback: function ($$v) {
                                  _vm.$set(_vm.filter, "purchaseObject", $$v)
                                },
                                expression: "filter.purchaseObject",
                              },
                            }),
                            _c("filter-input-text", {
                              attrs: {
                                label: "Заказчик",
                                placeholder:
                                  "Укажите наименование/ИНН заказчика",
                              },
                              model: {
                                value: _vm.filter.customer,
                                callback: function ($$v) {
                                  _vm.$set(_vm.filter, "customer", $$v)
                                },
                                expression: "filter.customer",
                              },
                            }),
                            _c("filter-input-text", {
                              attrs: {
                                label: "Рег.номер закупки",
                                placeholder: "Укажите номер закупки",
                              },
                              model: {
                                value: _vm.filter.regNumber,
                                callback: function ($$v) {
                                  _vm.$set(_vm.filter, "regNumber", $$v)
                                },
                                expression: "filter.regNumber",
                              },
                            }),
                            _c("filter-input-currency", {
                              attrs: {
                                label: "Сумма закупки от...",
                                placeholder: "Сумма закупки от...",
                              },
                              model: {
                                value: _vm.filter.priceStartFrom,
                                callback: function ($$v) {
                                  _vm.$set(_vm.filter, "priceStartFrom", $$v)
                                },
                                expression: "filter.priceStartFrom",
                              },
                            }),
                            _c("filter-input-currency", {
                              attrs: {
                                label: "Сумма закупки до...",
                                placeholder: "Сумма закупки до...",
                              },
                              model: {
                                value: _vm.filter.priceStartTo,
                                callback: function ($$v) {
                                  _vm.$set(_vm.filter, "priceStartTo", $$v)
                                },
                                expression: "filter.priceStartTo",
                              },
                            }),
                            _c(
                              "b-col",
                              {
                                staticClass: "mb-3",
                                attrs: { cols: "12", md: "6", lg: "3" },
                              },
                              [
                                _c("date-time-picker", {
                                  staticClass: "filter_datepicker",
                                  attrs: {
                                    id: "filter_from_purchase_start",
                                    label: "Укажите дату проведения с",
                                    "only-date": true,
                                    formatted: "DD.MM.YYYY",
                                    format: "YYYY-MM-DD",
                                    buttonNowTranslation: "Сегодня",
                                    "no-label": !!_vm.filter.orderDateStart,
                                    autoClose: "",
                                  },
                                  model: {
                                    value: _vm.filter.orderDateStart,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.filter,
                                        "orderDateStart",
                                        $$v
                                      )
                                    },
                                    expression: "filter.orderDateStart",
                                  },
                                }),
                                _c(
                                  "span",
                                  {
                                    staticClass:
                                      "fs-10 halfWhite-color label-position",
                                  },
                                  [_vm._v("Дата проведения с...")]
                                ),
                              ],
                              1
                            ),
                            _c(
                              "b-col",
                              {
                                staticClass: "mb-3",
                                attrs: { cols: "12", md: "6", lg: "3" },
                              },
                              [
                                _c("date-time-picker", {
                                  staticClass: "filter_datepicker",
                                  attrs: {
                                    id: "filter_to_purchase_start",
                                    label: "Укажите дату проведения по",
                                    "only-date": true,
                                    formatted: "DD.MM.YYYY",
                                    format: "YYYY-MM-DD",
                                    buttonNowTranslation: "Сегодня",
                                    "no-label": !!_vm.filter.orderDateFinish,
                                    autoClose: "",
                                  },
                                  model: {
                                    value: _vm.filter.orderDateFinish,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.filter,
                                        "orderDateFinish",
                                        $$v
                                      )
                                    },
                                    expression: "filter.orderDateFinish",
                                  },
                                }),
                                _c(
                                  "span",
                                  {
                                    staticClass:
                                      "fs-10 halfWhite-color label-position",
                                  },
                                  [_vm._v("Дата проведения по...")]
                                ),
                              ],
                              1
                            ),
                            _c("filter-multiple-select", {
                              staticClass: "atmo-entity-filters",
                              attrs: {
                                label: "Муниципалитеты",
                                options: _vm.forFilters.municipalities,
                                placeholder: "Выберите муниципалитет",
                                md: "12",
                                lg: "12",
                              },
                              model: {
                                value: _vm.filter.municipalities,
                                callback: function ($$v) {
                                  _vm.$set(_vm.filter, "municipalities", $$v)
                                },
                                expression: "filter.municipalities",
                              },
                            }),
                          ]
                        : _vm._e(),
                    ],
                    2
                  ),
                  _c(
                    "b-row",
                    { staticClass: "mt-1" },
                    [
                      _c(
                        "b-col",
                        {
                          staticClass: "atmo-buttons atmo-special atmo-mobile",
                        },
                        [
                          _c(
                            "b-button",
                            {
                              staticClass: "mr-2 text-uppercase",
                              attrs: { variant: "custom-white" },
                              on: {
                                click: function ($event) {
                                  return _vm.applyFilters(false)
                                },
                              },
                            },
                            [_vm._v("Найти")]
                          ),
                          _c(
                            "b-button",
                            {
                              staticClass: "mr-2 text-uppercase",
                              attrs: { variant: "custom-outline-light" },
                              on: {
                                click: function ($event) {
                                  _vm.showFilters = !_vm.showFilters
                                },
                              },
                            },
                            [
                              _vm._v(
                                _vm._s(
                                  _vm.showFilters
                                    ? "Меньше фильтров"
                                    : "Все фильтры"
                                ) + " "
                              ),
                            ]
                          ),
                          _c(
                            "b-button",
                            {
                              staticClass: "mr-2 text-uppercase",
                              attrs: { variant: "custom-outline-light" },
                              on: {
                                click: function ($event) {
                                  return _vm.clearFilter()
                                },
                              },
                            },
                            [_vm._v("Сбросить")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "b-container",
        { attrs: { fluid: "" } },
        [
          _c(
            "b-container",
            [
              !_vm.mobileMenu
                ? [
                    _c(
                      "b-nav",
                      {
                        staticClass:
                          "atmo-status-menu-controller atmo-menu nav-menu",
                      },
                      _vm._l(_vm.categories, function (category, cIndex) {
                        return _c(
                          "b-nav-item",
                          {
                            key: "category_" + cIndex,
                            attrs: { to: _vm.getNavLink(category.path) },
                          },
                          [_vm._v(_vm._s(category.title))]
                        )
                      }),
                      1
                    ),
                  ]
                : [
                    _c(
                      "b-navbar",
                      {
                        staticClass:
                          "atmo-status-menu-controller atmo-menu nav-mobile-menu",
                        attrs: { toggleable: "" },
                      },
                      [
                        _c("b-navbar-toggle", {
                          attrs: { target: "navbar-toggle-collapse" },
                          scopedSlots: _vm._u([
                            {
                              key: "default",
                              fn: function ({ expanded }) {
                                return [
                                  expanded
                                    ? _c("b-icon", {
                                        attrs: { icon: "chevron-bar-up" },
                                      })
                                    : _c("b-icon", {
                                        attrs: { icon: "chevron-bar-down" },
                                      }),
                                  _c("span", { staticClass: "pl-1" }, [
                                    _vm._v(_vm._s(_vm.navTitle)),
                                  ]),
                                ]
                              },
                            },
                          ]),
                        }),
                        _c(
                          "b-collapse",
                          {
                            attrs: {
                              id: "navbar-toggle-collapse",
                              "is-nav": "",
                            },
                          },
                          [
                            _c(
                              "b-navbar-nav",
                              { staticClass: "ml-auto" },
                              _vm._l(
                                _vm.categories,
                                function (category, cIndex) {
                                  return _c(
                                    "b-nav-item",
                                    {
                                      key: "category_" + cIndex,
                                      attrs: {
                                        to: _vm.getNavLink(category.path),
                                        exact: "",
                                        "exact-active-class": "disabled",
                                      },
                                    },
                                    [_vm._v(_vm._s(category.title))]
                                  )
                                }
                              ),
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
            ],
            2
          ),
        ],
        1
      ),
      _c(
        "b-container",
        {
          staticClass: "greyBg content-block-with-nav",
          attrs: { fluid: "", id: "content-block" },
        },
        [
          _c(
            "b-overlay",
            {
              attrs: {
                show: _vm.isLoading,
                rounded: "",
                opacity: "0.6",
                "spinner-variant": "primary",
              },
            },
            [
              _vm.isLoading || !_vm.notices.length
                ? _c("b-container", [_c("empty-request")], 1)
                : [
                    _vm.notices && _vm.meta.total > 0 && _vm.meta.last_page > 1
                      ? _c("b-pagination", {
                          staticClass: "my-1 pb-0",
                          attrs: {
                            "per-page": _vm.meta.per_page,
                            "total-rows": _vm.meta.total,
                            align: "center",
                          },
                          model: {
                            value: _vm.meta.current_page,
                            callback: function ($$v) {
                              _vm.$set(_vm.meta, "current_page", $$v)
                            },
                            expression: "meta.current_page",
                          },
                        })
                      : _vm._e(),
                    _vm._l(_vm.notices, function (notice) {
                      return _c(
                        "b-container",
                        { key: `fl223_${notice.id}` },
                        [
                          _c("fl223-notice-card", {
                            attrs: { notice: notice },
                          }),
                        ],
                        1
                      )
                    }),
                    _vm.notices && _vm.meta.total > 0 && _vm.meta.last_page > 1
                      ? _c("b-pagination", {
                          staticClass: "my-1",
                          attrs: {
                            "per-page": _vm.meta.per_page,
                            "total-rows": _vm.meta.total,
                            align: "center",
                          },
                          on: {
                            input: function ($event) {
                              return _vm.applyFilters()
                            },
                          },
                          model: {
                            value: _vm.meta.current_page,
                            callback: function ($$v) {
                              _vm.$set(_vm.meta, "current_page", $$v)
                            },
                            expression: "meta.current_page",
                          },
                        })
                      : _c("span", [_vm._v(" ")]),
                  ],
            ],
            2
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }