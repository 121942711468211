<template>
    <div>
        <text-row compact :label-cols-lg="4" :label-cols-sm="4" label="Предмет договора - наименование закупочной сессии" :value="purchase.purchase_terms.deliverable_group.name" />
        <text-row-price compact :label-cols-lg="4" :label-cols-sm="4" label="Цена договора, руб." :value="purchase.contract.price" />
        <text-row compact :label-cols-lg="4" :label-cols-sm="4" label="Статус договора" :value="purchase.contract.status.title" />
        <text-row-datetime compact :label-cols-lg="4" :label-cols-sm="4" label="Дата" :value="purchase.contract.date" />
        <text-row compact :label-cols-lg="4" :label-cols-sm="4" label="Статус закупки" :value="purchase.status.title" />
    </div>
</template>

<script>

export default {
    name: 'purchase-contract-info',
    props: {
        purchase: {
            type: Object,
            required: true
        },
    }
};
</script>
