<template>
    <div>
        <h5 class="grey-color mt-3 mb-1 main_text font-weight-bold">Разногласия по договору</h5>
        <div class="table-responsive">
            <table class="table b-table">
                <fragment v-for="(disagreement, index) in disagreements" :key="index">
                    <tr>
                        <th width="1">№</th>
                        <th>Протокол разногласий (поставщик)</th>
                        <th>Комментарий поставщика</th>
                        <th width="100" >Направлен</th>
                        <th width="50">Подпись</th>
                    </tr>
                    <tr>
                        <td :rowspan="!disagreement.customer_stored_document ? 1 : 3">{{ index + 1 }}</td>
                        <td>
                            <a class="atmo-button-link" style="color: var(--green-color) !important" :href="$getAttachmentDownloadLink(disagreement.supplier_stored_document.id)" target="_blank">{{ disagreement.supplier_stored_document.name }}</a>
                        </td>
                        <td>{{ disagreement.supplier_comment }}</td>
                        <td class="text-wrap">{{ $formatDateTime(disagreement.created_at) }}</td>
                        <td class="text-center text-nowrap">
                            <action-button-small icon="clipboard-check" title="Информация о подписи" @click="showSign(true, disagreement.supplier_signature)" />
                            <action-button-small v-if="disagreement.supplier_signature.poa_number && disagreement.supplier_signature.poa_check_link" class="ml-1" icon="clipboard-plus" title="Информация о МЧД" variant="warning" @click="showSign(true, disagreement.supplier_signature)" />
                            <action-button-small icon="cloud-download" title="Скачать файл подписи" variant="outline" class="ml-1" @click="download(disagreement.supplier_signature.md5, true, disagreement.supplier_stored_document.name)" />
                        </td>
                    </tr>
                    <template v-if="disagreement.customer_stored_document">
                        <tr>
                            <th>Ответ на протокол разногласий (заказчик)</th>
                            <th>Комментарий заказчика</th>
                            <th>Направлен</th>
                            <th>Подпись</th>
                        </tr>
                        <tr>
                            <td>
                                <a class="atmo-button-link" style="color: var(--green-color) !important" :href="$getAttachmentDownloadLink(disagreement.customer_stored_document.id)" target="_blank">{{ disagreement.customer_stored_document.name }}</a>
                            </td>
                            <td>{{ disagreement.customer_comment }}</td>
                            <td>{{ $formatDateTime(disagreement.updated_at) }}</td>
                            <td class="text-center text-nowrap">
                                <action-button-small icon="clipboard-check" title="Информация о подписи" @click="showSign(true, disagreement.customer_signature)" />
                                <action-button-small v-if="disagreement.customer_signature.poa_number && disagreement.customer_signature.poa_check_link" class="ml-1" icon="clipboard-plus" title="Информация о МЧД" variant="warning" @click="showSign(true, disagreement.customer_signature)" />
                                <action-button-small icon="cloud-download" title="Скачать файл подписи" variant="outline" class="ml-1" @click="download(disagreement.customer_signature.md5, true, disagreement.customer_stored_document.name)" />
                            </td>
                        </tr>
                    </template>
                </fragment>
            </table>
        </div>
        <show-sign-modal v-if="signData.signature" :signature="signData.signature" :org-is-customer="signData.isCustomer" :show="showSignModal" @close="showSignModal = false" />
    </div>
</template>

<script>

import {encodeBase64, saveAs} from "@progress/kendo-file-saver";

export default {
    name: 'disagreement-table',
    props: {
        disagreements: {
            type: Array,
            default: null
        },
        downloadZip: {
            type: Function,
            default: null
        }
    },
    data() {
        return {
            showSignModal: false,
            signData: {
                signature: null,
                isCustomer: false,
            }
        };
    },
    methods: {
        showSign(isCustomer, signature) {
            this.signData.isCustomer = isCustomer;
            this.signData.signature = signature;
            this.showSignModal = true;
        },
        download(signature, isCustomer, fileName) {
            const downloadData = 'data:text/plain;base64,' + encodeBase64(signature);
            const downloadFileName = (isCustomer ? 'customer_' : 'supplier_') + fileName + '.sig'
            saveAs(downloadData, downloadFileName);
        }
    }
};
</script>
