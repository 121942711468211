<template>
    <div>
        <template v-if="$store.getters.isLoggedIn && purchase.actions.customer.select_winner && purchase.private_data && purchase.private_data.winner_selection && purchase.status.id === 'summarizing'">
            <div id="atmo-winner-select" class="mx-1 mb-2">
                <b-btn variant="danger" class="fs-14 my-2 text-uppercase" style="color: white !important" size="lg" :href="$links.fl223_purchase_winner_selection_manual" target="_blank">
                    <i class="atmo-icon-play" />
                    Инструкция по формированию итогового протокола</b-btn
                >
            </div>

            <template v-if="purchase.private_data.proposals && purchase.private_data.proposals.length > 0">
                <b-row>
                    <b-col md="10">
                        <h2 class="grey-color font-weight-bold my-3">Предложения участников</h2>
                    </b-col>
                </b-row>
                <private-fl223-winner-selection-proposal-card
                    v-for="(proposal, index) in purchase.private_data.proposals"
                    :key="'proposal_' + index"
                    :proposal="proposal"
                    :purchase-id="purchase.id"
                    :supplier-requirements="purchase.supplier_requirements"
                    :purchase="purchase"
                    :index="index"
                    :with-rating="true"
                    v-model="proposal.rating"
                    :confirmed="purchase.private_data.winner_selection.confirmed"
                    :proposal-selected="winnerProposalsSelected"
                    @refresh="refresh" />
                <fl223-purchase-store-winner-selection-button :purchase="purchase" :ratings="proposalRatings" :proposals="purchase.private_data.proposals" @store="refresh" />
                <p class="text-danger">Процедура выбора победителя не завершена пока не загружен файл итогового протокола с подписями членов комиссии по выбору победителя</p>
            </template>
        </template>

        <template v-if="$store.getters.isLoggedIn && purchase.actions.customer.select_winner && purchase.private_data && purchase.private_data.winner_selection && purchase.private_data.winner_selection.confirmed && purchase.status.id === 'summarizing'">
            <text-header>Итоговый протокол закупочной сессии</text-header>
            <a class="btn btn-custom-green text-uppercase" :href="purchase.private_data.winner_selection.protocol_template_link">Скачать итоговый протокол закупочной сессии</a>
            <form-row-upload-single-document title="Подписанный членами комиссии итоговый протокол закупочной сессии" v-model="form.attachment" :v="$v.form.attachment" header-class="fs-14 fw-700" />
            <div class="w-100 text-right">
                <b-overlay :show="isDataSending" rounded opacity="0.6" spinner-small spinner-variant="primary" class="d-inline-block">
                    <b-button variant="custom-danger" class="text-uppercase" :disabled="$v.$invalid" @click="showConfirmModal = true">Отправить подписанный членами комиссии итоговый протокол закупочной сессии</b-button>
                </b-overlay>
            </div>
            <b-modal ref="confirm-modal" v-model="showConfirmModal" centered hide-header-close no-close-on-backdrop no-close-on-esc size="lg" title="Отправка подписанного итогового протокола закупочной сессии">
                <p class="text-left">Вы действительно хотите отправить подписанный членами комиссии итоговый протокол закупочной сессии?</p>
                <p class="text-left text-danger fw-600">ВНИМАНИЕ! Данное действие является необратимым, отменить его будет невозможно!</p>
                <div slot="modal-footer" class="w-100 text-right">
                    <b-button class="text-uppercase mr-2" variant="custom-outline-secondary" @click="showConfirmModal = false">Отмена</b-button>
                    <b-button class="text-uppercase" variant="custom-danger" @click="confirmWinnerSelection">Отправить подписанный членами комиссии итоговый протокол закупочной сессии</b-button>
                </div>
            </b-modal>
        </template>
    </div>
</template>

<script>
import PrivateFl223WinnerSelectionProposalCard from '@/components/products/fl223/partials/private-fl223-winner-selection-proposal-card.vue';
import Fl223PurchaseStoreWinnerSelectionButton from '@/components/products/fl223/partials/fl223-purchase-store-winner-selection-button.vue';
import FormRowUploadSingleDocument from '@/components/common/form-rows/form-row-upload-single-document.vue';
import { required } from 'vuelidate/lib/validators';

export default {
    name: 'private-fl223-winner-selection-block',
    components: { FormRowUploadSingleDocument, Fl223PurchaseStoreWinnerSelectionButton, PrivateFl223WinnerSelectionProposalCard },
    props: ['purchase'],
    data() {
        return {
            showConfirmModal: false,
            showStoreModal: false,
            form: {
                attachment: null
            },
            ratings: [],
            isDataSending: false
        };
    },
    validations() {
        return {
            form: {
                attachment: {
                    required
                }
            }
        };
    },
    methods: {
        async confirmWinnerSelection() {
            this.isDataSending = true;
            if (await this.$api.products.fl223.purchases.confirmWinnerSelection(this.purchase.id, this.form)) {
                this.showConfirmModal = false;
                this.$emit('confirm');
            }
            this.isDataSending = false;
        },
        refresh() {
            this.$emit('refresh');
        }
    },
    computed: {
        winnerProposalsSelected() {
            return this.purchase.private_data.proposals.filter((proposal) => proposal.status.id === 'winner').length > 0;
        },
        proposalRatings() {
            return this.purchase.private_data && this.purchase.private_data.proposals
                ? this.purchase.private_data.proposals.map(function (proposal) {
                      return {
                          id: proposal.id,
                          rating: parseInt(proposal.rating) ?? null
                      };
                  })
                : null;
        }
    }
};
</script>
