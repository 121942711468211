var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm.purchase.actions.customer.contract === "create"
        ? _c(
            "b-btn",
            {
              staticClass: "btn-card-action btn-card-action-danger my-1",
              attrs: {
                to: {
                  name: "Fl223PurchaseShow",
                  params: { id: _vm.purchase.id },
                  hash: "#atmo-contract",
                },
                target: "_blank",
              },
            },
            [_vm._v("Отправить договор")]
          )
        : _vm.purchase.actions.customer.contract === "sign"
        ? _c(
            "b-btn",
            {
              staticClass: "btn-card-action btn-card-action-danger my-1",
              attrs: {
                to: {
                  name: "Fl223PurchaseShow",
                  params: { id: _vm.purchase.id },
                  hash: "#atmo-contract",
                },
                target: "_blank",
              },
            },
            [_vm._v("Подписать договор")]
          )
        : _vm.purchase.actions.customer.contract === "reply"
        ? _c(
            "b-btn",
            {
              staticClass: "btn-card-action btn-card-action-danger my-1",
              attrs: {
                to: {
                  name: "Fl223PurchaseShow",
                  params: { id: _vm.purchase.id },
                  hash: "#atmo-contract",
                },
                target: "_blank",
              },
            },
            [_vm._v("Ответить на протокол разногласий")]
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }