var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "b-overlay",
        {
          attrs: {
            show: _vm.isDataSending,
            rounded: "",
            opacity: "0.6",
            "spinner-small": "",
            "spinner-variant": "primary",
          },
        },
        [
          _c(
            "b-btn",
            {
              staticClass: "mr-2",
              attrs: {
                disabled: !_vm.$store.getters.getCanSign,
                variant: "custom-green",
              },
              on: {
                click: function ($event) {
                  return _vm.$emit("click")
                },
              },
            },
            [_vm._v("Подписать и отправить договор")]
          ),
        ],
        1
      ),
      !_vm.$store.getters.getCanSign
        ? [
            _vm.$store.getters.getLoginType === "cert"
              ? _c(
                  "span",
                  { staticClass: "text-danger pl-3 pt-0 fs-14 w-50 my-auto" },
                  [
                    _vm._v(
                      "Для действия требуется загрузить/заменить МЧД на странице: "
                    ),
                    _c(
                      "router-link",
                      {
                        staticClass: "green-link",
                        attrs: { to: { name: "UserProfile" } },
                      },
                      [_vm._v("Данные учетной записи")]
                    ),
                  ],
                  1
                )
              : _c(
                  "span",
                  { staticClass: "text-danger pl-3 pt-0 fs-14 w-50 my-auto" },
                  [
                    _vm._v(
                      "Подписание возможно только при авторизации при помощи ЭЦП"
                    ),
                  ]
                ),
          ]
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }