var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-container",
    { staticClass: "mt-4 purchase_part px-0" },
    [
      _c(
        "b-card",
        {
          attrs: {
            "header-class": `purchase_part_header purchase_part-header_number ${_vm.cardCssClass} pr-2 py-2 position-relative`,
            "body-class": "purchase_part-body p-0",
            "border-variant": "light",
          },
          scopedSlots: _vm._u([
            {
              key: "header",
              fn: function () {
                return [
                  _c(
                    "b-row",
                    { staticClass: "w-100" },
                    [
                      _c(
                        "b-col",
                        { staticClass: "text-nowrap purchase-header-text" },
                        [
                          _c(
                            "b-badge",
                            {
                              staticClass: "my-auto fs-14 mr-2",
                              attrs: { variant: "primary" },
                            },
                            [_vm._v("223-ФЗ")]
                          ),
                          _c(
                            "router-link",
                            {
                              staticClass: "white-system_link-style",
                              attrs: {
                                to: {
                                  name: "Fl223PurchaseShow",
                                  params: { id: _vm.contract.id },
                                },
                                exact: "",
                                target: "_blank",
                              },
                            },
                            [_vm._v(_vm._s(_vm.contract.reg_number))]
                          ),
                          _vm.contract.purchase_object
                            ? [
                                _vm._v(
                                  ' На закупку "' +
                                    _vm._s(_vm.contract.purchase_object) +
                                    '"'
                                ),
                              ]
                            : _vm._e(),
                        ],
                        2
                      ),
                      _c(
                        "b-col",
                        {
                          staticClass: "text-nowrap text-uppercase",
                          attrs: { md: "auto" },
                        },
                        [_vm._v(" " + _vm._s(_vm.contract.status.title) + " ")]
                      ),
                    ],
                    1
                  ),
                  _c("b-icon", {
                    staticClass: "card-icon",
                    attrs: { icon: _vm.cardIcon },
                  }),
                ]
              },
              proxy: true,
            },
          ]),
        },
        [
          _c(
            "div",
            { staticClass: "purchase_part-subheader" },
            [
              _c(
                "b-col",
                {
                  staticClass: "d-flex align-items-center",
                  attrs: { xs: "12", sm: "6", lg: "3" },
                },
                [
                  _c("span", { staticClass: "fs-12 green-color" }, [
                    _vm._v(" Цена закупки: "),
                    _c("span", { staticClass: "fw-600" }, [
                      _vm._v(
                        _vm._s(_vm.$formatPrice(_vm.contract.start_price)) +
                          " руб."
                      ),
                    ]),
                  ]),
                ]
              ),
              _c(
                "b-col",
                {
                  staticClass: "d-flex align-items-center",
                  attrs: { xs: "12", sm: "6", lg: "3" },
                },
                [
                  _c("span", { staticClass: "fs-12 green-color" }, [
                    _vm._v(" Предложений: "),
                    _c("span", { staticClass: "fw-600" }, [
                      _vm._v(_vm._s(_vm.contract.proposals_count)),
                    ]),
                  ]),
                ]
              ),
              _c(
                "b-col",
                {
                  staticClass: "d-flex align-items-center",
                  attrs: { xs: "12", sm: "6", lg: "3" },
                },
                [
                  _c("span", { staticClass: "fs-12 green-color" }, [
                    _vm._v("Лучшее: "),
                    _c("span", { staticClass: "fw-600" }, [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.$formatPrice(_vm.contract.best_proposal_price) +
                              " руб."
                          )
                      ),
                    ]),
                  ]),
                ]
              ),
              _c(
                "b-col",
                {
                  staticClass: "d-flex align-items-center",
                  attrs: { xs: "12", sm: "6", lg: "3" },
                },
                [
                  _c("span", { staticClass: "fs-12 green-color" }, [
                    _vm._v(" Снижение: "),
                    _c("span", { staticClass: "fw-600" }, [
                      _vm._v(_vm._s(_vm.contract.discount_percentage) + " %"),
                    ]),
                  ]),
                ]
              ),
            ],
            1
          ),
          _c(
            "b-row",
            { staticClass: "purchase_part-body py-1" },
            [
              _c(
                "b-col",
                { staticClass: "purchase_part-body_col", attrs: { md: "4" } },
                [
                  _c("div", [
                    !!_vm.contract.planned_contract_execution_datetime
                      ? _c("div", { staticClass: "my-1" }, [
                          _c("p", { staticClass: "fs-12 m-0 grey-color" }, [
                            _vm._v("Планируемая дата исполнения:"),
                          ]),
                          _c(
                            "p",
                            { staticClass: "fw-600 fs-12 m-0 grey-color" },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.getDateFormat(
                                      _vm.contract
                                        .planned_contract_execution_datetime
                                    )
                                  ) +
                                  " "
                              ),
                            ]
                          ),
                        ])
                      : _vm._e(),
                    _vm.contract.status.id === "contract:active" ||
                    _vm.contract.status.id === "contract:fulfilled" ||
                    _vm.contract.status.id === "contract:unfulfilled" ||
                    _vm.contract.status.id === "contract:terminated"
                      ? _c("div", { staticClass: "my-1" }, [
                          _c("p", { staticClass: "fs-12 m-0 grey-color" }, [
                            _vm._v("Договор заключен:"),
                          ]),
                          _c(
                            "p",
                            {
                              staticClass:
                                "fs-12 m-0 grey-color fw-600 time_to_end",
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.getDateFormat(
                                      _vm.contract.contract_date
                                    )
                                  ) +
                                  " "
                              ),
                            ]
                          ),
                        ])
                      : _vm._e(),
                  ]),
                  _c("div", { staticClass: "my-1" }, [
                    _vm.contract.contract_price
                      ? _c("p", { staticClass: "fs-12 m-0 grey-color" }, [
                          _vm._v(" Цена договора: "),
                          _c("span", { staticClass: "fw-600" }, [
                            _vm._v(
                              _vm._s(
                                _vm.$formatPrice(_vm.contract.contract_price)
                              ) + " руб."
                            ),
                          ]),
                        ])
                      : _vm._e(),
                  ]),
                  _vm.isCreator
                    ? _c(
                        "div",
                        { staticClass: "my-1" },
                        [
                          _c("fl223-purchase-comment-edit-block", {
                            attrs: { purchase: _vm.contract },
                            on: { refresh: _vm.refresh },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                ]
              ),
              _c(
                "b-col",
                { staticClass: "purchase_part-body_col", attrs: { md: "4" } },
                [
                  _c("div", [
                    _c("div", { staticClass: "my-1" }, [
                      _c("p", { staticClass: "fs-12 grey-color m-0" }, [
                        _vm._v("Предмет закупки:"),
                      ]),
                      _c("p", { staticClass: "fs-12 grey-color fw-600" }, [
                        _vm._v(
                          " " +
                            _vm._s(_vm.contract.deliverable_group_name) +
                            " "
                        ),
                      ]),
                    ]),
                    _c(
                      "div",
                      { staticClass: "my-1 d-flex flex-column" },
                      [
                        _c("p", { staticClass: "fs-12 grey-color m-0" }, [
                          _vm._v("Заказчик:"),
                        ]),
                        !_vm.contract.is_customer
                          ? _c(
                              "router-link",
                              {
                                staticClass:
                                  "system_link-style fw-600 text-uppercase fs-12",
                                attrs: {
                                  to:
                                    "/organizations/" +
                                    _vm.contract.customer_id,
                                  target: "_blank",
                                },
                              },
                              [
                                _vm._v(
                                  " " + _vm._s(_vm.contract.customer_name) + " "
                                ),
                              ]
                            )
                          : _c("div", { staticClass: "fw-600 fs-12" }, [
                              _vm._v("Моя организация"),
                            ]),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "my-1 d-flex flex-column" },
                      [
                        _c("p", { staticClass: "fs-12 grey-color m-0" }, [
                          _vm._v("Поставщик:"),
                        ]),
                        _vm.contract.is_customer
                          ? _c(
                              "router-link",
                              {
                                staticClass:
                                  "system_link-style fw-600 text-uppercase fs-12",
                                attrs: {
                                  to:
                                    "/organizations/" +
                                    _vm.contract.supplier_id,
                                  target: "_blank",
                                },
                              },
                              [
                                _vm._v(
                                  " " + _vm._s(_vm.contract.supplier_name) + " "
                                ),
                              ]
                            )
                          : _c("div", { staticClass: "fw-600 fs-12" }, [
                              _vm._v("Моя организация"),
                            ]),
                      ],
                      1
                    ),
                    _c("div", { staticClass: "my-1" }, [
                      _c("p", { staticClass: "fs-12 m-0 grey-color" }, [
                        _vm._v(" Количество позиций: "),
                        _c("span", { staticClass: "fw-600" }, [
                          _vm._v(_vm._s(_vm.contract.deliverables_count)),
                        ]),
                      ]),
                    ]),
                    _vm.contract.delivery_addresses &&
                    _vm.contract.delivery_addresses.length
                      ? _c(
                          "div",
                          { staticClass: "my-1" },
                          [
                            _c("p", { staticClass: "fs-12 m-0 grey-color" }, [
                              _vm._v(
                                "Место доставки или указание на самовывоз:"
                              ),
                            ]),
                            _vm._l(
                              _vm.contract.delivery_addresses,
                              function (address, index) {
                                return _c(
                                  "p",
                                  {
                                    key: index,
                                    staticClass: "fs-12 m-0 grey-color fw-600",
                                  },
                                  [_vm._v(" " + _vm._s(address) + " ")]
                                )
                              }
                            ),
                          ],
                          2
                        )
                      : _vm._e(),
                  ]),
                ]
              ),
              _c(
                "b-col",
                {
                  staticClass:
                    "purchase_part-body_col atmo-column atmo-special-style",
                  attrs: { md: "4" },
                },
                [
                  _c("div", [
                    _c(
                      "p",
                      {
                        staticClass:
                          "d-flex align-items-center green-color fs-12 fw-600 my-1",
                      },
                      [
                        _c("i", { staticClass: "atmo-icon-search link-icon" }),
                        _c(
                          "router-link",
                          {
                            staticClass: "system_link-style",
                            attrs: {
                              to: {
                                name: "Fl223PurchaseShow",
                                params: { id: _vm.contract.id },
                              },
                              exact: "",
                              target: "_blank",
                            },
                          },
                          [_vm._v("Информация о закупке")]
                        ),
                      ],
                      1
                    ),
                    _c(
                      "p",
                      {
                        staticClass:
                          "d-flex align-items-center green-color fs-12 fw-600 my-1",
                      },
                      [
                        _c("i", { staticClass: "atmo-icon-shield link-icon" }),
                        _c(
                          "router-link",
                          {
                            staticClass: "system_link-style",
                            attrs: {
                              to: {
                                name: "Fl223PurchaseShow",
                                params: { id: _vm.contract.id },
                                hash: "#atmo-protocol",
                              },
                              exact: "",
                              target: "_blank",
                            },
                          },
                          [_vm._v("Итоговый протокол")]
                        ),
                      ],
                      1
                    ),
                    _c(
                      "p",
                      {
                        staticClass:
                          "d-flex align-items-center green-color fs-12 fw-600 my-1",
                      },
                      [
                        _c("i", {
                          staticClass: "atmo-icon-contract link-icon",
                        }),
                        _c(
                          "router-link",
                          {
                            staticClass: "system_link-style",
                            attrs: {
                              to: {
                                name: "Fl223PurchaseShow",
                                params: { id: _vm.contract.id },
                                hash: "#atmo-contract",
                              },
                              exact: "",
                              target: "_blank",
                            },
                          },
                          [_vm._v("Договор")]
                        ),
                      ],
                      1
                    ),
                    _vm.isCreator
                      ? _c(
                          "div",
                          { staticClass: "my-1" },
                          [
                            _c(
                              "b-btn",
                              {
                                staticClass:
                                  "btn-card-action btn-card-action-green my-1",
                                on: { click: _vm.copyPurchase },
                              },
                              [_vm._v("Копировать")]
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                  ]),
                ]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }