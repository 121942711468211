<template>
    <div>
        <system-preloader v-if="isLoading"></system-preloader>
        <b-row>
            <b-col md-12>
                <text-header-new v-if="isModeEdit">Редактирование запроса на добавление позиций КТРУ</text-header-new>
                <text-header-new v-else>Создание запроса на добавление позиций КТРУ</text-header-new>
            </b-col>
        </b-row>
        <b-row>
            <b-col v-if="!isModeEdit && isSelectCreateMethod">
                <div class="d-block text-center w-75 m-auto pb-5">
                    <b-btn class="btn btn-block btn-custom-green mb-4" @click="createKtruBasedOnExisting">Выбрать существующую позицию КТРУ для создания новой позиции на ее основе</b-btn>
                    <b-btn class="btn btn-block btn-custom-green" @click="createNewKtru">Добавить новую позицию КТРУ вручную</b-btn>
                </div>
            </b-col>
            <b-col v-else>
                <form-row-single-select v-model="form.ktru_group_id" :options="dictionaries.ktruGroups" :v="$v.form.ktru_group_id" label="Группа КТРУ" placeholder="Выберите группу КТРУ" />
                <form-row-single-select v-model="form.ktru_type_id" :options="dictionaries.ktruTypes" :v="$v.form.ktru_type_id" label="Тип КТРУ" placeholder="Выберите тип КТРУ" />
                <form-row-single-select v-model="form.country_of_origin_id" :options="dictionaries.countries" :v="$v.form.country_of_origin_id" label="Страна происхождения" placeholder="Выберите страну происхождения" />
                <form-row-edit-text v-model="form.name" :v="$v.form.name" label="Название" />
                <form-row-edit-textarea v-model="form.description" :rows="5" label="Описание" />
                <form-row-single-select-with-search v-model="form.okei_id" :options="dictionaries.okeis" :v="$v.form.okei_id" :search="searchOkeis" :is-loading="isOkeiLoading" label="Единица измерения (ОКЕИ)" label-field="breadcrumb" />
                <form-row-single-select-with-search v-model="form.okpd_id" :options="dictionaries.okpds" :v="$v.form.okpd_id" :search="searchOkpds" :is-loading="isOkpdLoading" label="ОКПД2" label-field="breadcrumb" />
                <b-table :fields="specFields" :items="form.specs" class="table-atmo" small hover responsive>
                    <template #head(actions)>
                        <b-button @click="addSpec" class="atmo-button-icon" v-b-popover.hover.top="'Добавить'">
                            <i class="atmo-icon-add-to-list"></i>
                        </b-button>
                    </template>
                    <template #cell(name)="data">
                        <b-form-input v-model="data.item.name" :disabled="data.index === 0" :state="!$v.form.specs.$each.$iter[data.index].name.$invalid" class="fs-14"></b-form-input>
                    </template>
                    <template #cell(value)="data">
                        <b-form-textarea v-model="data.item.value" class="fs-14" :state="!$v.form.specs.$each.$iter[data.index].value.$invalid"></b-form-textarea>
                    </template>
                    <template #cell(actions)="data">
                        <action-button-small v-if="data.index !== 0" icon="trash" @click="removeSpec(data.index)" />
                    </template>
                </b-table>
            </b-col>
        </b-row>
        <b-row v-if="!isSelectCreateMethod || isModeEdit">
            <b-overlay :show="isDataSending" rounded opacity="0.6" spinner-variant="primary">
                <b-col>
                    <b-button variant="custom-outline-secondary" class="mx-2 text-uppercase" @click="onCancel">Отмена</b-button>
                    <b-button variant="custom-danger" class="text-uppercase mx-2" @click="onSaveDraft">Сохранить черновик</b-button>
                    <b-button variant="custom-green" class="text-uppercase mx-2" @click="onSubmit" :disabled="$v.form.$invalid">Разместить запрос</b-button>
                </b-col>
            </b-overlay>
        </b-row>
        <purchase-specification-modal :show="showKtruSelectModal" :allow-create-request="false" @cancel="cancelKtruSelectModal" @add="processKtruSelect" />
    </div>
</template>

<style scoped>
.transparent-card {
    border: none;
    background-color: rgba(1, 1, 1, 0);
}
</style>

<script>
import { required } from 'vuelidate/lib/validators';
import isEmpty from 'lodash.isempty';
import debounceMixin from '@/mixins/debounce';

const initialFormSpecs = {
    name: 'Минимальные требования',
    value: ''
};
const initialForm = {
    id: null,
    name: '',
    existing_ktru_item_id: null,
    ktru_group_id: null,
    ktru_type_id: null,
    description: '',
    okei_id: null,
    okpd_id: null,
    country_of_origin_id: null,
    specs: [{ ...initialFormSpecs }]
};

export default {
    mixins: [debounceMixin],
    name: 'KtruRequestsCreate',
    metaInfo() {
        return {
            title: this.isModeEdit ? 'Редактирование запроса на добавление позиций КТРУ' : 'Создание запроса на добавление позиций КТРУ'
        };
    },
    data() {
        return {
            isSelectCreateMethod: true,
            showKtruSelectModal: false,
            selectedKtru: null,
            isLoading: true,
            isOkpdLoading: false,
            isOkeiLoading: false,
            isDataSending: false,
            specFields: [
                { key: 'name', label: 'Название', sortable: false, tdClass: 'align-text-top py-1', thStyle: { width: '40%' } },
                { key: 'value', label: 'Значение', sortable: false, tdClass: 'align-text-top py-1', thStyle: { width: '60%' } },
                { key: 'actions', label: '', sortable: false, tdClass: 'align-text-top py-1', thClass: 'py-1', thStyle: { width: '1px' } }
            ],
            form: {
                ...initialForm
            },
            dictionaries: {
                ktruGroups: [],
                ktruTypes: [],
                okeis: [],
                okpds: [],
                countries: []
            }
        };
    },
    validations: {
        form: {
            name: {
                required,
                minLength: (value) => {
                    return value.trim().length > 3;
                }
            },
            ktru_type_id: { required },
            ktru_group_id: { required },
            country_of_origin_id: { required },
            okei_id: { required },
            okpd_id: { required },
            specs: {
                required,
                $each: {
                    name: { required },
                    value: { required }
                }
            }
        }
    },
    async mounted() {
        await this.fillData();
        this.isLoading = false;
    },
    methods: {
        async fillData() {
            const data = await this.$api.dictionary.getList(['ktru_groups', 'ktru_types', 'countries']);
            this.dictionaries.ktruGroups = data.ktru_groups;
            this.dictionaries.ktruTypes = data.ktru_types;
            this.dictionaries.countries = data.countries;
            if (this.isModeEdit) {
                const response = await this.$api.cabinet.ktru.ktruRequests.getDraft(this.$route.params.id).catch(() => this.onCancel());
                this.dictionaries = { ...this.dictionaries, ...response.dictionary_items };
                this.form = response.form;
            }
        },
        async searchOkpds(query) {
            this.debounce(async () => {
                this.isOkpdLoading = true;
                await this.$api.dictionary.search('okpds', query).then((response) => {
                    this.dictionaries.okpds = response;
                });
                this.isOkpdLoading = false;
            });
        },
        async searchOkeis(query) {
            this.debounce(async () => {
                this.isOkeiLoading = true;
                await this.$api.dictionary.search('okeis', query).then((response) => {
                    this.dictionaries.okeis = response;
                });
                this.isOkeiLoading = false;
            });
        },
        createKtruBasedOnExisting() {
            this.showKtruSelectModal = true;
        },
        createNewKtru() {
            this.isSelectCreateMethod = false;
        },
        cancelKtruSelectModal() {
            this.showKtruSelectModal = false;
        },
        processKtruSelect(selectedKtru) {
            this.showKtruSelectModal = false;
            this.form.name = selectedKtru.ktru.name;
            this.form.existing_ktru_item_id = selectedKtru.ktru.id;
            const foundKtruGroup = this.dictionaries.ktruGroups.find((i) => i.id === selectedKtru.ktru_group_id);
            this.form.ktru_group_id = foundKtruGroup ? selectedKtru.ktru_group_id : null;
            this.form.ktru_type_id = selectedKtru.ktru.ktru_type_id;
            this.form.description = selectedKtru.ktru.description;
            this.dictionaries.okeis = [{ id: selectedKtru.ktru.okei.id, breadcrumb: selectedKtru.ktru.okei.breadcrumb }];
            this.form.okei_id = selectedKtru.ktru.okei_id;
            this.dictionaries.okpds = [{ id: selectedKtru.ktru.okpd.id, breadcrumb: selectedKtru.ktru.okpd.breadcrumb }];
            this.form.okpd_id = selectedKtru.ktru.okpd_id;
            this.form.country_of_origin_id = selectedKtru.ktru.country_of_origin_id ?? null;
            if (isEmpty(selectedKtru.ktru.specs) || selectedKtru.ktru.specs[0].name !== 'Минимальные требования') {
                selectedKtru.ktru.specs.unshift(initialFormSpecs);
            }
            this.form.specs = selectedKtru.ktru.specs;
            this.isSelectCreateMethod = false;
        },
        async onSaveDraft() {
            this.isDataSending = true;
            await this.$api.cabinet.ktru.ktruRequests
                .store(this.form, true, this.form.id)
                .then(async (response) => {
                    if (!this.isModeEdit) this.form.id = response.id;
                    await this.$router.replace({ name: 'KtruRequestsEdit', params: { id: this.form.id } });
                })
                .finally(() => {
                    this.isDataSending = false;
                });
        },
        async onSubmit() {
            this.isDataSending = true;
            await this.$api.cabinet.ktru.ktruRequests
                .store(this.form, false, this.form.id)
                .then(() => {
                    this.onCancel();
                })
                .finally(() => {
                    this.isDataSending = false;
                });
        },
        onCancel() {
            this.$router.push({ name: 'KtruRequestsList' }).catch(() => {});
        },
        addSpec() {
            const specLength = this.form.specs.length;
            if (this.form.specs[specLength - 1].name.trim() + this.form.specs[specLength - 1].value.trim() !== '') {
                this.form.specs.push({ name: '', value: '' });
            }
        },
        removeSpec(index) {
            this.form.specs.splice(index, 1);
        }
    },
    computed: {
        isModeEdit() {
            return !!this.$route.params.id && this.$route.name === 'KtruRequestsEdit';
        }
    }
};
</script>
