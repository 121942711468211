<template>
    <div>
        <h5 class="grey-color mt-3 mb-1 main_text font-weight-bold">Договор</h5>
        <b-table :busy="isDataSending" :fields="fields" :items="contract.contract_files" class="table-atmo" small hover responsive>
            <template #cell(created_at)="data">
                {{ $formatDateTime(data.item.created_at) }}
            </template>
            <template #cell(contract_attachment)="data">
                <template v-if="data.item.is_archived">(Архив)&nbsp;</template>
                <a :href="$getAttachmentDownloadLink(data.item.contract_attachment.id)" class="atmo-button-link" style="color: var(--green-color) !important" target="_blank">{{ data.item.contract_attachment.name }}</a>
                <div v-if="!data.item.is_archived && contract.status.subtitle">
                    {{ contract.status.subtitle }}
                </div>
            </template>
            <template #cell(contract_with_signature)="data">
                <action-button-small v-if="!data.item.is_archived && contract.zip_name" icon="file-earmark-zip" title="Скачать одним файлом" variant="outline" class="ml-1" @click="downloadZip" />
            </template>
            <template #cell(sign_customer)="data">
                <template v-if="data.item.customer_signature">
                    <action-button-small icon="clipboard-check" title="Информация о подписи" @click="showSign(true, data.item.customer_signature)" />
                    <action-button-small v-if="data.item.customer_signature.poa_number && data.item.customer_signature.poa_check_link" class="ml-1" icon="clipboard-plus" title="Информация о МЧД" variant="warning" @click="showSign(true, data.item.customer_signature)" />
                    <action-button-small icon="cloud-download" title="Скачать файл подписи" variant="outline" class="ml-1" @click="download(data.item.customer_signature.md5, true, data.item.contract_attachment.name)" />
                </template>
            </template>
            <template #cell(sign_supplier)="data">
                <template v-if="data.item.supplier_signature">
                    <action-button-small icon="clipboard-check" title="Информация о подписи" @click="showSign(false, data.item.supplier_signature)" />
                    <action-button-small v-if="data.item.supplier_signature.poa_number && data.item.supplier_signature.poa_check_link" class="ml-1" icon="clipboard-plus" title="Информация о МЧД" variant="warning" @click="showSign(false, data.item.supplier_signature)" />
                    <action-button-small icon="cloud-download" title="Скачать файл подписи" variant="outline" class="ml-1" @click="download(data.item.supplier_signature.md5, false, data.item.contract_attachment.name)" />
                </template>
            </template>
        </b-table>
        <show-sign-modal v-if="signData.signature" :signature="signData.signature" :org-is-customer="signData.isCustomer" :show="showSignModal" @close="showSignModal = false" />
    </div>
</template>

<script>

import {encodeBase64, saveAs} from "@progress/kendo-file-saver";

export default {
    name: 'contract-files-table',
    props: {
        contract: {
            type: Object,
            required: true
        },
        isDataSending: {
            type: Boolean,
            default: false
        },
        downloadZip: {
            type: Function,
            required: true
        }
    },
    data() {
        return {
            showSignModal: false,
            signData: {
                signature: null,
                isCustomer: false,
            },
            fields: [
                { key: 'created_at', label: 'Дата и время', sortable: false, tdClass: 'align-text-top text-nowrap py-1', thClass: 'py-1', thStyle: { width: '200px', maxWidth: '200px' } },
                { key: 'contract_attachment', label: 'Документ', sortable: false, tdClass: 'align-text-top py-1', thClass: 'text-nowrap py-1' },
                { key: 'contract_with_signature', label: 'Документооборот целиком', sortable: false, tdClass: 'align-text-top py-1', thClass: 'py-1', thStyle: { width: '50px' } },
                { key: 'sign_customer', label: 'Подпись заказчика', sortable: false, tdClass: 'align-text-top text-nowrap py-1', thClass: 'py-1', thStyle: { width: '50px' } },
                { key: 'sign_supplier', label: 'Подпись поставщика', sortable: false, tdClass: 'align-text-top text-nowrap py-1', thClass: 'py-1', thStyle: { width: '50px' } }
            ],
        };
    },
    methods: {
        showSign(isCustomer, signature) {
            this.signData.isCustomer = isCustomer;
            this.signData.signature = signature;
            this.showSignModal = true;
        },
        download(signature, isCustomer, fileName) {
            const downloadData = 'data:text/plain;base64,' + encodeBase64(signature);
            const downloadFileName = (isCustomer ? 'customer_' : 'supplier_') + fileName + '.sig'
            saveAs(downloadData, downloadFileName);
        }
    },
    computed: {
    }
};
</script>
